<template>
  <exception-page type="404" />
</template>

<script>
import ExceptionPage from '@/components/Exception'

export default {
  components: {
    ExceptionPage,
  },
}
</script>

<style scoped></style>
